import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <Helmet 
        title={frontmatter.metaTitle}
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap" rel="stylesheet" />
        <meta name="description" content={frontmatter.metaDescription} />
        <meta name="google-site-verification" content="329vyuhJLST6Ej-QjKgi3tU8NP00BQ03TyVu4KxTl0w" />
      </Helmet>
      <div className="markdown-doc">
        <div className="markdown-doc__container">
          <h1>{frontmatter.title}</h1>
          {/* <h2>{frontmatter.date}</h2> */}
          <div
            className="markdown-doc__container__content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        metaTitle
        metaDescription
      }
    }
  }
`